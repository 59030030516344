<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">The Pouch Blog.</h1>
      <hr>
      <p class="subtitle">Money-saving hints and tips from the Pouch team.</p>

      <p-list>
        <p-list-item v-for="post in posts.slice(0, 6)" :key="post.slug">
          <router-link :to="{ name: 'post', params: { slug: post.slug } }">{{ post.title }}</router-link>
        </p-list-item>
      </p-list>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">The Pouch Blog.</h1>
      <p class="subtitle">Money-saving hints and tips from the Pouch team.</p>
      <hr>
    </template>

    <template slot="main">
      <div class="columns is-multiline is-gapless" v-masonry item-selector=".post-container">
        <div class="column is-6 post-container" v-masonry-tile v-for="post in posts" :key="post.slug">
          <post :post="post"></post>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import Post from './components/Post'
import { mapState } from 'vuex'

export default {
  components: { Post },

  computed: mapState({
    posts: state => state.blog.posts
  })
}
</script>
