<template>
  <article class="post">
    <router-link :to="{ name: 'post', params: { slug: post.slug } }">
      <div class="post-image">
        <figure class="image is-2by1">
          <img v-lazy="post.image" :alt="post.title">
        </figure>
      </div>
      <div class="post-preview content">
        <p>
          <small>
            <span class="has-text-grey-light">{{ post.date }} &middot; </span>
            <span class="has-text-secondary">{{ post.minutes }} MIN READ</span>
          </small>
        </p>

        <h2 class="title is-5">{{ post.title }}</h2>

        <p class="has-text-grey">{{ post.preview }}</p>

        <span class="has-text-weight-semibold has-text-primary">READ MORE</span>
      </div>
    </router-link>
  </article>
</template>

<script>
export default {
  props: {
    post: Object
  }
}
</script>
